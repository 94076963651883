<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>회사소개</h2>
				<a @click="onBack()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="boxWhite"></div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import terms from '@/api/terms';
export default {
	mounted() {
		this.getTerms();
	},
	data() {
		return {
			term1: '',
			term2: '',
		};
	},
	methods: {
		async getTerms() {
			let res = await terms.get();
			this.term1 = res.data[0];
			this.term2 = res.data[1];
		},
		onBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped></style>
